"use client";
import { useState } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import styles from "@/app/styles/Molecules/TapToPlayCover.module.scss";
import ImageLink from "../../atoms/ImageLink/ImageLink";
const PlayerVodV2 = dynamic(
	() => import("@/app/components/molecules/PlayerVodV2/PlayerVodV2")
);
const VideoDuration = dynamic(
	() => import("@/app/components/atoms/VideoDuration/VideoDuration")
);
// const InteractiveLink = dynamic(
// 	() => import("@/app/components/organisms/BloqueHeroGrid/InteractiveLink")
// );

declare const window: WindowWithDataLayer;
export default function TapToplayCover({
	post,
	loading,
	blockData,
	cardPosition,
	isTopFive = false,
}: any) {
	const pathName = usePathname();
	const [dataLayer, setDataLayer] = useState(blockData);

	const handleOnClick = () => {
		setDataLayer(() => {
			blockData.content_name = "VOD>";
			blockData.contentTitle = post?.title;
			return blockData;
		});
		handleDataLayerOnClick(dataLayer, cardPosition + 1, pathName);
		setShowPlayer(true);
		return null;
	};

	const [showPlayer, setShowPlayer] = useState<boolean>(false);

	return (
		<div className={styles?.imgWrapper}>
			<>
				{!showPlayer && post?.isVideo && (
					<Image
						src={post?.thumbnail?.url?.webp ?? "/assets/placeholder_.svg"}
						alt={post?.thumbnail?.alt ?? "Imagen del video"}
						width={post?.thumbnail?.width ?? "320"}
						height={post?.thumbnail?.height ?? "180"}
						loading={loading}
						placeholder="blur"
						blurDataURL={`${
							process.env.NEXT_PUBLIC_BLURIMAGE ||
							"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
						}`}
						sizes="(min-width: 1580px) 894px, (min-width: 1280px) calc(80vw - 354px), (min-width: 360px) calc(96.44vw - 47px), calc(50vw + 110px)"
						onClick={handleOnClick}
					/>
				)}
				{!showPlayer && !post?.isVideo && (
					<ImageLink
						post={post}
						loading={loading}
						blockData={dataLayer}
						cardPosition={cardPosition}
					/>
				)}
				{post?.isLiveblog && (
					<div className={`${styles[`topFive__liveblog__caption--on`]}`}>
						<span className={`${styles[`topFive__liveblog__captionText`]}`}>
							LIVEBLOG
						</span>
						<span className={`${styles[`topFive__liveblog__pulseIcon`]}`} />
					</div>
				)}
				{post?.isVideo && !showPlayer && (
					<VideoDuration
						duration={post?.duration}
						isPlay={true}
						isTopFive={isTopFive}
					/>
				)}
				{showPlayer && (
					<PlayerVodV2
						idVideo={post?.cmsId}
						type="clips"
						cmsid={true}
						mute={true}
						autoplay={true}
						currentPlaceholder={post?.thumbnail?.url?.webp}
					/>
				)}
			</>
			{/* post?.isVideo ? (
				<>
					<Image
						src={post?.thumbnail?.url?.webp ?? "/assets/placeholder_.svg"}
						alt={post?.thumbnail?.alt ?? "Imagen del video"}
						width={post?.thumbnail?.width ?? "320"}
						height={post?.thumbnail?.height ?? "180"}
						loading="lazy"
						placeholder="blur"
						blurDataURL={`${
							process.env.NEXT_PUBLIC_BLURIMAGE ||
							"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
						}`}
						sizes="(min-width: 1580px) 894px, (min-width: 1280px) calc(80vw - 354px), (min-width: 360px) calc(96.44vw - 47px), calc(50vw + 110px)"
					/>
				</>
			) : post?.isLiveblog ? (
				<InteractiveLink
					url={post?.path || "/"}
					blockData={blockData}
					cardPosition={0}
					title={post?.title || "nmas"}
				>
					<Image
						src={post?.thumbnail?.url?.webp ?? "/assets/placeholder_.svg"}
						alt={post?.thumbnail?.alt ?? "Imagen del video"}
						width={post?.thumbnail?.width ?? "320"}
						height={post?.thumbnail?.height ?? "180"}
						loading={loading}
						placeholder="blur"
						blurDataURL={`${
							process.env.NEXT_PUBLIC_BLURIMAGE ||
							"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
						}`}
						sizes="(min-width: 1580px) 894px, (min-width: 1280px) calc(80vw - 354px), (min-width: 360px) calc(96.44vw - 47px), calc(50vw + 110px)"
					/>
					<>
						<div className={`${styles[`topFive__liveblog__caption--on`]}`}>
							<span className={`${styles[`topFive__liveblog__captionText`]}`}>
								LIVEBLOG
							</span>
							<span className={`${styles[`topFive__liveblog__pulseIcon`]}`} />
						</div>
					</>
				</InteractiveLink>
			) : (
				<InteractiveLink
					url={post?.path || "/"}
					blockData={blockData}
					cardPosition={0}
					title={post?.title || "nmas"}
				>
					<Image
						src={post?.thumbnail?.url?.webp ?? "/assets/placeholder_.svg"}
						alt={post?.thumbnail?.alt ?? "Imagen del video"}
						width={post?.thumbnail?.width ?? "320"}
						height={post?.thumbnail?.height ?? "180"}
						loading={loading}
						placeholder="blur"
						blurDataURL={`${
							process.env.NEXT_PUBLIC_BLURIMAGE ||
							"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
						}`}
						sizes="(min-width: 1580px) 894px, (min-width: 1280px) calc(80vw - 354px), (min-width: 360px) calc(96.44vw - 47px), calc(50vw + 110px)"
					/>
				</InteractiveLink>
			) */}
			{/* post?.isVideo && !showPlayer && (
				<VideoDuration
					duration={post?.duration}
					isPlay={true}
					isTopFive={isTopFive}
				/>
			) */}
		</div>
	);
}
