import { Children } from "react";
import dynamic from "next/dynamic";
import parse from "html-react-parser";
import Script from "next/script";

import GetPolicyBlock from "@/app/queries/getPolicyBlock";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";

import style from "@/app/styles/Blocks/BloqueTextoHtml.module.scss";

import type { GetPolicyBlockProps } from "@/app/types/";

const Breadcrumb = dynamic(() =>
	import("@/app/components/molecules/Breadcrumb/Breadcrumb")
);

export const BloqueTextoHtml = async ({
	id,
	type,
}: {
	id: string;
	type: string;
}) => {
	const { data, ...rest } = await fetchApolloData(GetPolicyBlock, {
		policyBlockId: id,
		blockType: type,
	});

	if (rest?.errors) {
		console.error(rest?.errors);
	}

	const {
		getPolicyBlock: { body, title, hasBreadcrumb },
	} = data as { getPolicyBlock: GetPolicyBlockProps };

	const regExp = /"(([^"]*).js)"/g;
	const regJsExp = /<script/g;

	let isScript = false;
	let jsString: string[] = [];
	let cleanBody: string = "";

	if (regJsExp.test(body)) {
		const jsMatch = body.match(regExp);
		
		if (jsMatch) {
			isScript = true;
			const scriptSources = jsMatch.map((scriptSource: string) => {
				return scriptSource.replaceAll('"', "");
			});
			jsString = scriptSources;

			const scriptsRemoveRegEx = new RegExp(scriptSources.join("|"), "g");
			cleanBody = body.replace(scriptsRemoveRegEx, "");
		}
	}

	return (
		<section className={`${style["textoHtml__wrapper"] + " bloqueTextoHtml"}`}>
			{hasBreadcrumb && <Breadcrumb themeColor={"light"} getLasted={true} />}
			<article className={`${style["textoHtml__wrapperText"]}`}>
				<h1>{title}</h1>
				{isScript ? parse(cleanBody) : parse(body)}
			</article>
			{isScript && jsString.length ? (
				Children.toArray(
					jsString.map((jsString) => {
						return <Script defer src={jsString || ""} />;
					})
				)
			) : (
				<></>
			)}
		</section>
	);
};
export default BloqueTextoHtml;
