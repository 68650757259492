import { gql } from "@apollo/client";

export default gql`
	query GetTopFive($getTopFiveId: String!) {
		getTopFive(id: $getTopFiveId) {
			id
			type
			uuid
			title
			subtitle
			description
			name
			hasTitle
			isDark
			borderColor
			cmsName
			content {
				id
				type
				title
				summary
				topic
				duration
				cmsId
				thumbnail {
					alt
					caption
					width
					height
					url {
						webp
						shapes {
							rect
							square
							vertical
							vintage
							vintageVertical
						}
					}
				}
				path
			}
		}
	}
`;
