import { CSSProperties } from "react";
import dynamic from "next/dynamic";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import { GetMosaicoType } from "./interfaces/MosaicoRespone";
import GetMosaico from "@/app/queries/getMosaico";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import mosaico from "@/app/styles/Blocks/BloqueMosaico.module.scss";
const WidgetTitle = dynamic(
	() => import("@/app/components/molecules/WidgetTitle/WidgetTitle")
);
const MosaicoFour = dynamic(() => import("./MosaicoFour"));
const MosaicoTwoThree = dynamic(() => import("./MosaicoTwoThree"));

type ComponentProps = {
	type: string;
	id: string;
	position?: number;
	total?: number;
};

export const BloqueMosaico = async ({
	type,
	id,
	position = 0,
	total,
}: ComponentProps) => {
	const { data, ...rest } = await fetchApolloData(GetMosaico, {
		mosaicoId: id,
	});
	if (!data?.getMosaico) return null;
	if (rest?.errors) {
		console.error(rest?.errors);
	}

	const {
		title,
		borderColor,
		content,
		subTitle,
		isDark,
		cmsName,
	}: GetMosaicoType = data.getMosaico || {};
	const blockTypeLayer =
		type?.replace("block_content--", "")?.split("_")?.join(" ") || "";
	const totalContent = content?.length?.toString();
	const theme = isDark ? "dark" : "light";

	const blockDataLayer = {
		colorBloque: theme?.toLowerCase() || "",
		contenidosBloque: `${content?.length || 0} contenidos`,
		countWidget: `${total || 0}`,
		estiloBloque: "16:9",
		event: "select_content",
		eventAction: "click",
		posicionBloque: position + 1 ? `${position + 1}` : "0",
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockTypeLayer || "") ?? undefined,
		tipoElemento: "widget",
		tituloBloque: title ?? undefined,
		descripcionBloque: cmsName,
		clickBloque: undefined,
		content_type: undefined,
		section: undefined,
		contentTitle: undefined,
	};

	const loading = position === 0 || position === 1 ? "eager" : "lazy";

	return (
		<section
			className={`${mosaico["mosaico"]}`}
			style={
				{
					"--mosaicoBGColor": `${
						theme.toLocaleLowerCase() === "light" ? "#fff" : "#00283B"
					}`,
					marginBottom: isDark ? "0" : "24px",
					paddingBottom: isDark ? "24px" : "0",
				} as CSSProperties
			}
		>
			<div className={`${title && mosaico["mosaico__wrapperHead"]}`}>
				<WidgetTitle
					title={title || ""}
					text={subTitle || ""}
					lineColor={borderColor || "transparent"}
					isDark={isDark}
				/>
			</div>

			{totalContent === "2" || totalContent === "3" ? (
				<MosaicoTwoThree
					newsInfo={content}
					theme={theme}
					noContent={totalContent}
					loading={loading}
					blockDataLayer={blockDataLayer}
				/>
			) : (
				<MosaicoFour
					newsInfo={content}
					lineColor={borderColor}
					theme={theme}
					noContent={totalContent}
					loading={loading}
					blockDataLayer={blockDataLayer}
				/>
			)}
		</section>
	);
};
export default BloqueMosaico;
