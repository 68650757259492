import { CSSProperties } from "react";
import dynamic from "next/dynamic";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetLiveAndStories from "@/app/queries/getLiveAndStories";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import { BlockProps } from "@/app/types/BlockProps.type";
import styles from "@/app/styles/Blocks/BloqueAperturaLive.module.scss";
const CardVideoNews = dynamic(
	() => import("@/app/components/molecules/CardVideoNews/CardVideoNews")
);
const PlayerStreaming = dynamic(
	() => import("@/app/components/molecules/PlayerStreaming/PlayerStreaming")
);
const WidgetTitle = dynamic(
	() => import("@/app/components/molecules/WidgetTitle/WidgetTitle")
);

export const BloqueAperturaLive = async ({
	type,
	id,
	position = 0,
	total,
}: BlockProps) => {
	const { data, ...rest } = await fetchApolloData(GetLiveAndStories, {
		getLiveAndStoriesId: id,
	});
	if (rest?.errors) {
		console.error(rest?.errors);
	}
	const blockData = data?.getLiveAndStories ?? null; // : LiveBlock

	const relatedContent = blockData?.hasRelated ? blockData?.relatedContent : [];
	const isDark = blockData?.isDark;
	const howMany: number = blockData?.hasRelated ? relatedContent?.length : 0;

	let hasTwo = false;
	if (howMany > 0) {
		hasTwo = howMany === 2 ? true : false;
	}

	const livePath =
		blockData?.liveChannel?.liveTitle === "Foro TV"
			? "/foro-tv"
			: "/noticieros";
	// const blockType = type.replace("block_content--", "");
	const cmsName = blockData?.cmsName;
	const blockType = type.replace("block_content--", "").split("_").join(" ");
	const blockDataLayer = {
		event: "select_content",
		clickBloque: undefined,
		colorBloque: `${blockData?.isDark ? "dark" : "light"}`,
		contenidosBloque: blockData?.relatedContent
			? `${blockData?.relatedContent.length} contenidos`
			: "0 contenidos",
		countWidget: total,
		descripcionBloque: blockData?.cmsName,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: blockData?.blockTitle ?? undefined,
	};

	let urlLiveShare;
	switch (blockData?.liveChannel?.path || "") {
		case "forotv-en-vivo":
			urlLiveShare = "/foro-tv/";
			break;
		case "noticieros-en-vivo":
			urlLiveShare = "/noticieros/";
			break;
		case "noticieros-digital":
			urlLiveShare = "/nmas-media/";
			break;
		default:
			urlLiveShare = "/";
			break;
	}
	return (
		<section
			className={isDark ? `${styles?.live} ${styles?.dark}` : `${styles?.live}`}
		>
			<div className={styles?.live__inner_wrapper}>
				{position !== 0 && (
					<WidgetTitle
						title={blockData?.blockTitle}
						text={blockData?.blockDescription}
						lineColor={blockData?.borderColor}
						isDark={isDark}
					/>
				)}
			</div>
			<div className={styles?.live__player_container}>
				<div className={styles?.live__player_box}>
					<PlayerStreaming
						ui={blockData?.liveChannel?.ui}
						path={blockData?.liveChannel?.path}
						uid={blockData?.liveChannel?.uid}
					/>
				</div>
			</div>
			<div className={styles?.live__inner_wrapper}>
				<section
					className={
						hasTwo
							? `${styles?.live__two_content}`
							: `${styles?.live__quad_content}`
					}
				>
					<div className={styles?.live__playerside}>
						
						<div className={styles?.live__live_info}>
							<p
								className={styles?.live__live_tag}
								style={
									{
										"--titleColor": `${isDark ? "#EDF4F8" : "#103D53"}`,
									} as CSSProperties
								}
							>
								Programación en vivo
							</p>
							<h3
								className={styles?.live__live_title}
								style={
									{
										"--titleColor": `${isDark ? "#EDF4F8" : "#103D53"}`,
									} as CSSProperties
								}
							>
								{blockData?.liveChannel?.liveTitle}
							</h3>
							<p
								className={styles?.live__live_headline}
								style={
									{
										"--headLineColor": `${isDark ? "#EDF4F8" : "#474B4D"}`,
									} as CSSProperties
								}
							>
								{blockData?.liveChannel?.liveDescription}
							</p>
						</div>
					</div>

					{howMany > 0 && (
						<ul className={styles?.live__cards_list}>
							{relatedContent.map((item: any, i: number) => (
								<li
									key={item?.id}
									id={`item-${item?.id}`}
									className={styles?.live__list_item}
								>
									<CardVideoNews
										post={item}
										blockData={blockDataLayer}
										isDark={isDark}
										cardPosition={i + 1}
									/>
								</li>
							))}
						</ul>
					)}
				</section>
			</div>
		</section>
	);
};
export default BloqueAperturaLive;
