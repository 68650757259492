import dynamic from "next/dynamic";
import fetchApoloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetTopFive from "@/app/queries/getTopFive";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import { BlockProps } from "@/app/types/BlockProps.type";
import { BlockTopFiveType } from "./type/BlockTopFive.type";
import { PostCard } from "@/app/types/PostCard.type";
import styles from "@/app/styles/Blocks/BlockTopFive.module.scss";
const WidgetTitle = dynamic(
	() => import("@/app/components/molecules/WidgetTitle/WidgetTitle")
);
const CardVertical = dynamic(
	() => import("@/app/components/molecules/cards/CardVertical/CardVertical")
);
const CardTapToPlay = dynamic(
	() => import("@/app/components/molecules/cards/CardTapToplay/CardTapToPlay")
);

export const BlockTopFive = async ({
	id,
	type,
	position = 0,
	total,
}: BlockProps) => {
	const { data, ...rest } = await fetchApoloData(GetTopFive, {
		getTopFiveId: id,
	});

	if (rest?.error) {
		console.error(rest?.error);
	}

	const blockData: BlockTopFiveType = data?.getTopFive;
	const blockType = type.replace("block_content--", "").split("_").join(" ");
	const blockDataLayer = {
		event: "select_content",
		colorBloque: `${blockData?.isDark ? "dark" : "light"}`,
		contenidosBloque: `${blockData?.content.length} contenidos`,
		countWidget: total,
		descripcionBloque: blockData?.cmsName,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: blockData?.title ?? undefined,
	};

	// Separa el primer post y hace un array del resto
	const firstPost = blockData?.content?.[0];
	firstPost.isVideo = blockData?.content?.[0]?.type === "node--video";
	firstPost.isLiveblog = blockData?.content?.[0]?.type === "node--liveblog";

	const latterPosts = blockData?.content.filter((post: PostCard, i: number) => {
		if (i > 0) {
			post.isVideo = post?.type === "node--video";
			post.isLiveblog = post?.type === "node--liveblog";
			return post;
		}
		return null;
	});
	const { isDark = false } = blockData ?? {};
	return (
		<section
			className={
				isDark
					? `${styles?.tf_section} ${styles?.tf_section__dark}`
					: `${styles?.tf_section}`
			}
		>
			<div className={styles?.tf_section__innerWrapper}>
				<WidgetTitle
					title={blockData?.title}
					text={blockData?.summary}
					lineColor={blockData?.borderColor}
					isDark={isDark}
				/>
				<div className={styles?.tf_section__postsPool}>
					<div
						className={`${styles?.tf_section__cardWrapper} ${styles?.tf_section__firstPost}`}
					>
						<span className={styles?.tf_section__numeration_one}>1 </span>
						<CardTapToPlay
							post={firstPost}
							index={0}
							isDark={isDark}
							blockData={blockDataLayer}
							isTopFive={true}
						/>
					</div>
					<div className={styles?.tf_section__latterContainer}>
						{latterPosts?.map((post: PostCard, i: number) => (
							<div
								key={`${post?.id}-${i}`}
								className={styles?.tf_section__cardWrapper}
							>
								<span className={styles?.tf_section__numeration}>{`${
									i + 2
								}. `}</span>
								<CardVertical
									post={post}
									index={i + 1}
									isVideo={post?.isVideo ?? false}
									isDark={isDark}
									blockData={blockDataLayer}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default BlockTopFive;
