import { notFound } from "next/navigation";
import dynamic from "next/dynamic";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetStoryPage from "@/app/queries/getStoryPage";
import { Suspense } from "react";
import NewsArticle from "@/app/site-tools/structured-data/NewsArticle";
import { VideoObject } from "@/app/site-tools/structured-data/VideoObject";
import {
	getMonthString,
	secondsToISO8601,
} from "@/app/helpers/timeHelpers/timeHelpers";
import StoryPage from "@/app/components/molecules/StoryPage/StoryPage";

const BlocksStoryPage = dynamic(
	() => import("@/app/components/organisms/BloqueStoryPage/BlocksStoryPage"),
	{ ssr: false }
);

type Props = {
	isPreview?: boolean;
	previewData?: any;
	isVariantBulletin?: boolean;
	pagePath?: string;
	type?: String;
	position?: Number;
	total?: Number;
};

export default async function StoryApertureLoad({
	isPreview,
	previewData,
	isVariantBulletin = false,
	pagePath,
	type,
	position,
	total,
}: Props) {
	const { data, ...rest } = await fetchApolloData(GetStoryPage, {
		sectionPath: pagePath,
	});

	const spData = data?.getStoryPages;
	if (rest?.errors) {
		console.error(rest?.errors[0]?.message, rest?.errors[0]?.extensions);
	}
	const mainVid = spData?.videoStory;
	const { relatedVideos } = spData;

	const vidList = relatedVideos?.map((element: any, i: number) => {
		const date = `${element?.date
			?.replace(" | ", " ")
			?.replace(" hrs", "")} UTC-6`;
		const replaceWith = date?.split(" ")[0]?.toLowerCase();
		return {
			"@context": "https://schema.org",
			"@type": "VideoObject",
			position: i + 1,
			name: element?.title,
			url: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}${
				element?.path
			}/`,
			duration: secondsToISO8601(element?.duration),
			uploadDate: new Date(
				date?.replace(date?.split(" ")[0], getMonthString(replaceWith) ?? "jan")
			),
			dateTime: element?.dateTime,
			thumbnailUrl: element?.image?.imageUrl?.includes(".jpg")
				? [element?.image?.imageUrl]
				: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(
						/\/+$/,
						""
				  )}/assets/placeholder_.svg`,
			description: element?.description,
			embedUrl: `https://www.nmas.com.mx/preview-video/?idvideo=${element?.cmsid}`,
			contentUrl: element?.contentUrl ?? undefined,
		};
	});

	const itemList = {
		"@context": "https://schema.org",
		"@type": "ItemList",
		itemListElement: vidList,
	};

	const mainVideo = spData?.videoStory
		? {
				cmsId: mainVid?.cmsid,
				title: mainVid?.editorialTitle ?? mainVid?.title,
				summary: mainVid?.description,
				thumbnail:
					mainVid?.image?.imageUrl ??
					`${process?.env?.NEXT_PUBLIC_BASE_URL?.replace(
						/\/+$/,
						""
					)}/assets/screen_n.png`,
				uploadDate: new Date(spData?.dateTime)?.toISOString(),
				duration: parseInt(mainVid?.duration),
				// contentUrl: "",
				term: spData?.term,
				isBroadcastEvent: false,
		  }
		: {};

	return (data && data !== undefined) || previewData ? (
		<>
			<NewsArticle mainNews={spData} />
			{spData?.videoStory?.cmsid && (
				<>
					<VideoObject mainVid={mainVideo} />
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: JSON.stringify(itemList, null, 2),
						}}
					/>
				</>
			)}
			{isPreview && previewData?.published === null ? (
				<StoryPage data={previewData} isVariantBulletin={isVariantBulletin} />
			) : (
				<StoryPage data={data} isVariantBulletin={isVariantBulletin} />
			)}
			{/* Render de los bloques custom */}
			<Suspense fallback={<div>Cargando...</div>}>
				<BlocksStoryPage pagePath={pagePath} />
			</Suspense>
			{/* Render de los bloques custom */}
		</>
	) : (
		notFound()
	);
}
function getCurrentStartEndDate(airdate: any, airtime: any, arg2: number) {
	throw new Error("Function not implemented.");
}
